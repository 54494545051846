import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Article from "../components/article/article"
import ArticleFeatured from '../components/article-featured/article-featured'
import WidgetCategories from '../components/widgets/widget-categories/widget-categories'
import WidgetMostpopular from '../components/widgets/widget-mostpopular/widget-mostpopular'
import chunk from "lodash.chunk"
import AdSense from 'react-adsense'
import axios from 'axios'

// This would normally be in a Redux store or some other global data store.
if (typeof window !== `undefined`) {
  window.articlesToShow = 10
}

class IndexPage extends React.Component {

  constructor() {
    super()
    let articlesToShow = 10
    if (typeof window !== `undefined`) {
      articlesToShow = window.articlesToShow
    }

    this.state = {
      showingMore: articlesToShow > 10,
      articlesToShow,
      popularPages: undefined
    }
  }

  update() {
    const distanceToBottom =
      document.documentElement.offsetHeight -
      (window.scrollY + window.innerHeight)
    if (this.state.showingMore && distanceToBottom < 100) {
      this.setState({ articlesToShow: this.state.articlesToShow + 10 })
    }
    this.ticking = false
  }

  handleScroll = () => {
    if (!this.ticking) {
      this.ticking = true
      requestAnimationFrame(() => this.update())
    }
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll)
    this.getGoogleAnalyticsData()
    // const script = document.createElement("script");
    // script.text = "var infolinks_pid = 3214286; var infolinks_wsid = 0;"
    // document.body.appendChild(script);
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll)
    window.articlesToShow = this.state.articlesToShow
  }

  async getGoogleAnalyticsData(){
    try {
      const res = await axios.get('https://codespot-reporting-api.herokuapp.com/api');
      Promise.resolve(res.data.result.data.rows).then(values => {
        this.setState({popularPages: values})
      })
    } catch (err) {
      console.error(err);
    }
  }

  loadMore = event => {
    this.setState({
      articlesToShow: this.state.articlesToShow + 3,
      showingMore: true,
    })
  }

  render(){
    const allArticlesJSON = this.props.data.allMarkdownRemark
    const articles = allArticlesJSON.edges.map(e => e.node)

    return(
      <Layout>
        <SEO title="Software Development and Programming" article={false}/>
        <div id='featured' className='row'>
          {chunk(articles.slice(0, this.state.articlesToShow), 3).map((chunk, i) => (
            chunk.map(article => (
              article.frontmatter.featured === true ? <div key={article.id} className="col-lg-8">
                <div className='ad-leaderboard-root'>
                  <AdSense.Google
                    className='ad-leaderboard'
                    client='ca-pub-2585619948311874'
                    slot='9722845538'
                    style={{ display: 'inline-block', height: 90, width:728}} 
                    format=''               
                  />
                </div>
                <ArticleFeatured article={article}/>
                </div> : null
            ))
          ))}
          <div className='col-lg-4'>
            <WidgetMostpopular mostPopular={this.state.popularPages} />
            <WidgetCategories articles={articles} />
          </div>
        </div>

        <div id='newest' className='row'>
          {chunk(articles.slice(0, this.state.articlesToShow), 3).map((chunk, i) => (
            chunk.map(article => (
              article.frontmatter.featured === false ? <div key={article.id} className="col-lg-4"><Article article={article}/></div> : null
            ))
          ))}
        </div>

        <div style={{textAlign: 'center', marginTop: '20px'}}>
          {!this.state.showingMore && <button 
            type="button" 
            className="btn" 
            style={{padding: '7px 18px', borderRadius: '5px', backgroundColor: '#ff7a59', color: '#fff'}} 
            onClick={this.loadMore}>Show More
          </button>}
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const data = graphql`
query indexPageQuery {
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }){
    edges{
      node{
        id
        html
        frontmatter{
          path
          date
          title
          author
          image
          category
          tags
          featured
        }
      }
    }
  }
}
`
