import React from 'react'
import Link from 'gatsby-link'
import styles from './article-featured.module.css'
import TimeAgo from 'react-timeago'
import readingTime from 'reading-time'


const divStyle = (url) => ({
  background: 'url( ' + url + ')',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
});

const ArticleFeatured = (props) => (
  <div key={props.article.id} className={styles.articleFeatured}>
    <div>
      <Link to={props.article.frontmatter.path}>
        <div style={divStyle(props.article.frontmatter.image)} className={styles.articleFeaturedImg}></div>
      </Link>
    </div>
    <div className={styles.articleFeaturedContent}>
      <Link to={props.article.frontmatter.path}>
        <h2 className={styles.articleFeaturedTitle}>{props.article.frontmatter.title}</h2>
      </Link>
      <p className={styles.articleFeaturedMeta}>
       {props.article.frontmatter.category} | {readingTime(props.article.html).text} | <TimeAgo date={props.article.frontmatter.date}/>
      </p>
    </div>
  </div>
)

export default ArticleFeatured