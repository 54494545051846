import React from 'react'
import Link from 'gatsby-link'
import styles from '../widget-categories/widget-categories.module.css'

var categoryList = {};
var sortedCategoryList = [];

const categories = (articles) => {
  articles.map(article => (
    addCategory(article.frontmatter.category)
  ))
}

function addCategory(category){

  if (category in categoryList) {
    categoryList[category] += 1;
  } else {
    categoryList[category] = 1;
  }
}

function sortCategories(){
  sortedCategoryList = Object.keys(categoryList).sort(function(a,b){return categoryList[a] - categoryList[b]})
}

// const categoryPath = (category) => {
//   return '/category?' + category
// }

const WidgetCategories = (props) => (
  <div>
    <h3 className={styles.widgetCategoriesTitle}>Popular Topics</h3>
    <div className={styles.widgetCategories}>
      {categories(props.articles)}
      {sortCategories()}
      <ul className={styles.categotyList}>
        {sortedCategoryList.reverse().map(category => (
          <li key={category}><Link to={'/category/' + category.toLowerCase() + '/'} className={styles.category}>{category}</Link></li>
        ))}
      </ul>
    </div>
  </div>
)

export default WidgetCategories