import React from 'react'
import Link from 'gatsby-link'
import styles from '../article/article.module.css'
import TimeAgo from 'react-timeago'
import readingTime from 'reading-time'

const divStyle = (url) => ({
  background: 'url( ' + url + ')',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '205px',
});

const Article = (props) => (
  <div key={props.article.id} className={styles.article}>
    <div className={styles.articleImage}>
      <Link to={props.article.frontmatter.path}>
        <div style={divStyle(props.article.frontmatter.image)}></div>
      </Link>
    </div>
    <div className={styles.articleContent}>
      <Link to={props.article.frontmatter.path}>
        <h2 className={styles.articleTitle}>{props.article.frontmatter.title}</h2>
      </Link>
        <p className={styles.articleMeta}>
          {props.article.frontmatter.category} | {readingTime(props.article.html).text} | <TimeAgo date={props.article.frontmatter.date}/>
        </p>
    </div>
  </div>
)

export default Article